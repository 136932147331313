import React, {FC} from 'react';
import {useTranslation, WidgetProps} from '@wix/yoshi-flow-editor';
import {ControllerContext} from './ControllerContext';
import {ControllerProps} from '../../../types/app.types';
import {LocaleKeysProvider} from '../../../locale-keys/LocaleKeys';
import './index.css';
import PaymentRequestApp from '../PaymentRequestApp/PaymentRequestApp';

const Widget: FC<WidgetProps<ControllerProps>> = (controllerProps: WidgetProps<ControllerProps>) => {
  const {t} = useTranslation();

  return (
    <ControllerContext value={controllerProps}>
      <LocaleKeysProvider
        translateFn={(key: any, options: any, ...rest: unknown[]) =>
          t(key, {...options, interpolation: {escapeValue: false}, ...rest})
        }>
        <PaymentRequestApp />
      </LocaleKeysProvider>
    </ControllerContext>
  );
};

export default Widget;

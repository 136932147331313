import React from 'react';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {TPAComponentsProvider} from 'wix-ui-tpa/cssVars';

export enum PaymentRequestAppDataHook {
  root = 'CheckoutAppDataHook.root',
  footer = 'CheckoutAppDataHook.footer',
  header = 'CheckoutAppDataHook.header',
  summary = 'CheckoutAppDataHook.summary',
}

const PaymentRequestApp = () => {
  const {isRTL} = useEnvironment();

  return (
    <TPAComponentsProvider value={{rtl: isRTL}}>
      <div>
        <span>Payment Request App</span>
      </div>
    </TPAComponentsProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default PaymentRequestApp;
